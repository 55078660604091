.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav-wrapper{
  position: sticky;
  top: 0;
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeaea;
  padding: 10px 20px;
  z-index: 1999;
}
.nav-img-wrapper{
  width: 200px;
  height: 90px;
}
.nav-img-wrapper img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.toggle-icon{
  width: 25px;
  height: 25px;
}
.toggle-icon svg{
  width: 100%;
  height: 100%;
}
.nav-menu li a{
  font-size: 14px;
}
.nav-menu-mobile{
  background-color: #eeeaee;
  position: absolute;
  top: 80px;
  right: 0;
  margin: .4rem;
  padding: .6rem;
  min-width: 14rem;
  row-gap: 2rem;
  border-radius: .5rem;
 
}
.order-wrapper{
  /* e9d195 */
  /* e8aa07 */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1rem;
  margin-top: 1rem;
}
.price-wrapper{
  
}
.divider{
  margin-top: 30px;
  width:2000px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.price-wrapper .price-new{
  font-weight: 500;
  font-size: 14px;
}
.price-wrapper .price-old{
  font-size: 12px;
}
.slick-slide > div {
	margin: 0 10px;
  }
  .slick-list {
	margin: 0 -10px;
  }
  .slick-dots li button:before
  {
	  font-size: 15px;
	  line-height: 20px;
	  
  }
  /* .slick-prev:before {
	content: "<";
	color: red;
	font-size: 30px;
	background-color: #595959;
  }
  
  .slick-next:before {
	content: ">";
	color: red;
	font-size: 30px;
  } */
.order-product{
	/* w-full max-w-sm bg-white rounded-lg shadow-md hover:scale-105 duration-300 */
	gap: 1rem;
	display: flex;
	justify-content: center;
	width: 100%;
	margin: .3rem;
}
.order-product-img-wrapper{
	/* p-2 rounded-t-lg w-[600px] h-[300px] object-contain */
	height: 400px;
	max-width: 200px;
}
.order-product-img-wrapper img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.order-product-btn{
 /* position: absolute;	
 bottom: 0; */
  width: 100%;
  text-align: center;
  background: #e8aa07;
  color: #000;
  border-radius: .3rem;
  transition: .5 ease;
  padding: .5rem 0rem;
}
.order-product-btn:hover{
  color: #e8aa07;
  background: #000;
}
.order-service-btn{
  width: 75%;
  text-align: center;
  background: #000;
  color: #e8aa07;
  border-radius: .3rem;
  transition: .5 ease;
  padding: .5rem .8rem;
  margin-top: .7rem;
}
.order-service-btn:hover{
  color: #000;
  background: #e8aa07;
}
.new-claim{
  position: relative;
  height: 400px;
}
.claim-btn{
  position: absolute;
  bottom: 0;
  width: 80%;
  text-align: center;
  background: #e8aa07;
  color: #000;
  border-radius: .3rem;
  transition: .5 ease;
  padding: .5rem 0rem;
  margin-bottom: 1rem;
}
.new-service{
  position: relative;
  height: 400px;
 
}
.order-news-btn{
  position: absolute;
  bottom: 0;
  width: 90%;
  text-align: center;
  background: #000;
  color: #e8aa07;
  border-radius: .3rem;
  transition: .5 ease;
  padding: .5rem .8rem;
  margin-top: .2rem;
  margin-bottom: .7rem;
}
.order-news-btn:hover{
  color: #000;
  background: #e8aa07;
}

.click-btn{
  color: blue;
}

/* view comment page css */
/**
 * Oscuro: #283035
 * Azul: #03658c
 * Detalle: #c7cacb
 * Fondo: #dee1e3
 ----------------------------------*/


/** ====================
 * Lista de Comentarios
 =======================*/
 .comments-container {
	margin: 60px auto 15px;
	width: 768px;
}

.comments-container h1 {
	font-size: 36px;
	color: #283035;
	font-weight: 400;
}

.comments-container h1 a {
	font-size: 18px;
	font-weight: 700;
}

.comments-list {
	margin-top: 30px;
	position: relative;
}

/**
 * Lineas / Detalles
 -----------------------*/
.comments-list:before {
	content: '';
	width: 2px;
	height: 100%;
	background: #c7cacb;
	position: absolute;
	left: 32px;
	top: 0;
}

.comments-list:after {
	content: '';
	position: absolute;
	background: #c7cacb;
	bottom: 0;
	left: 27px;
	width: 7px;
	height: 7px;
	border: 3px solid #dee1e3;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.reply-list:before, .reply-list:after {display: none;}
.reply-list li:before {
	content: '';
	width: 60px;
	height: 2px;
	background: #c7cacb;
	position: absolute;
	top: 25px;
	left: -55px;
}


.comments-list li {
	margin-bottom: 15px;
	display: block;
	position: relative;
}

.comments-list li:after {
	content: '';
	display: block;
	clear: both;
	height: 0;
	width: 0;
}

.reply-list {
	padding-left: 88px;
	clear: both;
	margin-top: 15px;
}
/**
 * Avatar
 ---------------------------*/
.comments-list .comment-avatar {
	width: 65px;
	height: 65px;
	position: relative;
	z-index: 99;
	float: left;
	border: 3px solid #FFF;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	overflow: hidden;
}

.comments-list .comment-avatar img {
	width: 100%;
	height: 100%;
}

.reply-list .comment-avatar {
	width: 50px;
	height: 50px;
}

.comment-main-level:after {
	content: '';
	width: 0;
	height: 0;
	display: block;
	clear: both;
}
/**
 * Caja del Comentario
 ---------------------------*/
.comments-list .comment-box {
	width: 680px;
	float: right;
	position: relative;
	-webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.15);
	-moz-box-shadow: 0 1px 1px rgba(0,0,0,0.15);
	box-shadow: 0 1px 1px rgba(0,0,0,0.15);
}

.comments-list .comment-box:before, .comments-list .comment-box:after {
	content: '';
	height: 0;
	width: 0;
	position: absolute;
	display: block;
	border-width: 10px 12px 10px 0;
	border-style: solid;
	border-color: transparent #FCFCFC;
	top: 8px;
	left: -11px;
}

.comments-list .comment-box:before {
	border-width: 11px 13px 11px 0;
	border-color: transparent rgba(0,0,0,0.05);
	left: -12px;
}

.reply-list .comment-box {
	width: 610px;
}
.comment-box .comment-head {
	background: #FCFCFC;
	padding: 10px 12px;
	border-bottom: 1px solid #E5E5E5;
	overflow: hidden;
	-webkit-border-radius: 4px 4px 0 0;
	-moz-border-radius: 4px 4px 0 0;
	border-radius: 4px 4px 0 0;
}

.comment-box .comment-head i {
	float: right;
	margin-left: 14px;
	position: relative;
	top: 2px;
	color: #A6A6A6;
	cursor: pointer;
	-webkit-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.comment-box .comment-head i:hover {
	color: #03658c;
}

.comment-box .comment-name {
	color: #283035;
	font-size: 14px;
	font-weight: 700;
	float: left;
	margin-right: 10px;
}

.comment-box .comment-name a {
	color: #283035;
}

.comment-box .comment-head span {
	float: left;
	color: #999;
	font-size: 13px;
	position: relative;
	top: 1px;
}

.comment-box .comment-content {
	background: #FFF;
	padding: 12px;
	font-size: 15px;
	color: #595959;
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	border-radius: 0 0 4px 4px;
}

.comment-box .comment-name.by-author, .comment-box .comment-name.by-author a {color: #03658c;}
.comment-box .comment-name.by-author:after {
	/* content: 'autor'; */
	background: #03658c;
	color: #FFF;
	font-size: 12px;
	padding: 3px 5px;
	font-weight: 700;
	margin-left: 10px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.search-business-container{
	margin: 0 auto;
	max-width: 1160px;
	width: 100%;
}
.search-business{
	/* max-w-[1240] mx-auto grid md:grid-cols-2 mt-10 */
	display: flex;
	width: 100%;
	/* justify-content: center;
	align-items: center; */
	gap: 2rem;
	margin: 2rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.search-business .search-business-logo-wrapper{
	width: 400px;
	height: 150px;
}
.search-business .search-business-logo-wrapper img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.search-business .description{
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	max-width: 40rem;
	padding: 0rem 1.5rem;
}
.search-business .description h3{
	font-size: 2rem;
	font-weight: 700;
}
.search-business .description h4{
	font-size: 1.4rem;
	font-weight: 500;
}
.search-business .description h5{
	font-size: 1.3rem;
	font-weight: 400;
}
.search-business .description .details{
	font-size: 1rem;
	margin: 1rem 0;
}
.search-business .description-btn{
	display: flex;
	align-items: center;
	gap: 1rem;
	flex-wrap: wrap;
}
.description-btn button{
	border: 1px solid rgba(0, 0, 0, 0.2);
	background: transparent;
	border-radius: .3rem;
	font-size: .7rem;
	padding: .5rem;
	transition: background .5s ease;
}
.description-btn button:hover{
	background: #e8aa07;
	color: #282c34;
}
.description-btn .btn-heart{
	display: flex;
	gap: .3rem;
	align-items: center;
}
.new-service{
	/* background-color: red;
	max-w-[1240px]  mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 */
}
/** =====================
 * Responsive
 ========================*/
@media only screen and (max-width: 766px) {
	.search-business{
		flex-direction: column;
		gap: 1rem;
		margin: 0rem;
	}
	.search-business .search-business-logo-wrapper{
		width: 100%;
	}
	.comments-container {
		width: 480px;
	}
	.search-business .description h3{
		font-size: 1.3rem;
	}
	.search-business .description h4{
		font-size: 1.2rem;
	}
	.search-business .description h5{
		font-size: 1rem;
	}
	.comments-list .comment-box {
		width: 390px;
	}

	.reply-list .comment-box {
		width: 320px;
	}
}
@media screen and (max-width: 472px) {
  /* space, space-cat, space-desc, space-btn */
  #space{
	
	font-size: 15px;
  }
  #space-btn{
	font-size: 15px;
  }
  #comment{
	font-size: medium;
  }
}