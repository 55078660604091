@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
    --body-color: #FEFBF6;
}

*,
*::before,
*::after{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
/* *{ overflow-y: scroll } */

html{
	scroll-behavior: smooth;
/*	font-size: 10px; same as 62.5% and makes scaling much better*/
	/* font-size: 62.5%; */
	overflow-x: hidden;
    
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container{
    max-width: 100%;
    margin: 0 auto;
}
.menu{
    background-color: black;
}
.main{
    width: 100%;
    display: flex;
    height: auto;
    margin: 0 auto;
}
.side1{
    width: 75%;
}
.side2{
    width: 25%;
}
@media screen and (max-width: 900px) {
    .main{
        flex-direction: column;
        width: 100%;
    }
    .side1,
    .side2{
        width: 100%;
    }
}
